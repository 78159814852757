import bff from "./bff"
import especifica from "./especifica"
import datadog from "./datadog"
import storage from "utils/storage"

export const setHeaderToken = (token) => {
  if (!token) return
  storage.session.setItem("bearerToken", token)

  bff.defaults.headers.common["Token"] = `Bearer ${token}`
  especifica.defaults.headers.common["Token"] = `Bearer ${token}`
}

export default {
  bff,
  especifica,
  datadog,
}
