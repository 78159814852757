import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import jwt_decode from "jwt-decode"
import FeatureToggleWidget from "./components/FeatureToggleWidget"
import SEO from "components/SEO"
import { OfferProvider } from "hooks/useOffer"
import { RenegProvider } from "hooks/useReneg"
import { AuthOptinProvider } from "context/useAuthOptin"
import { CustomizeSelectContractsProvider } from "context/useCustomizeSelectContracts"
import storage from "utils/storage"
import { setCrossSellOrigin, setUtmSourceByQueryString } from "utils/crossSell"
import { DesenrolaProvider } from "context/useDesenrola"
import ga4 from "utils/trackings/ga4"
import { LayoutProvider } from "contexts/Layout"
import { LeadProvider } from "contexts/Lead"
import noIndexPage from "./utils/noIndexPage"
import { COOKIEBAR_MFE_URL } from "gatsby-env-variables"
import If from "@mobi/components/If"
import {
  saveCookieBarStylesToStorage,
  getCookieBarStyles,
} from "utils/cookieBar"

const Layout = ({ children }) => {
  const sessionToken = storage.session.getItem("bearerToken") || ""
  const [isLoaded, setIsLoaded] = useState(false)
  const styles = getCookieBarStyles()

  if (sessionToken?.length) {
    try {
      const decodedToken = jwt_decode(sessionToken)

      if (!Object.keys(decodedToken).length) return

      const tokenData = decodedToken?.data
      const isDataAnObject = typeof tokenData === "object"

      const data = isDataAnObject ? tokenData : JSON.parse(tokenData)

      if (!!data.lead_uuid) {
        storage.session.setItem("lead_uuid", data.lead_uuid)
      }
    } catch (error) {
      console.error("error to decoded token --", error)
    }
  }

  // useEffect(() => {
  //   if (
  //     typeof window.__VERSION__ === "undefined" &&
  //     typeof window.__TAG__ === "undefined"
  //   ) {
  //     console.group("%c " + "APP VERSION", "color: #ec7000")
  //     console.log("%c " + `VERSION: ${GIT_VERSION} `, "color: #253bff")
  //     console.log("%c " + `TAG: ${GIT_TAG_VERSION}`, "color: #253bff")
  //     console.groupEnd()

  //     window.__VERSION__ = GIT_VERSION
  //     window.__TAG__ = GIT_TAG_VERSION
  //   }
  // }, [])

  useEffect(() => {
    setUtmSourceByQueryString()
    setCrossSellOrigin()
    ga4.setDefaultParameters()

    window.saveCookieBarStylesToStorage = saveCookieBarStylesToStorage
    setIsLoaded(true)
  }, [])

  return (
    <>
      <If
        condition={isLoaded}
        renderIf={
          <>
            <Helmet>
              <link rel="stylesheet" href={`${COOKIEBAR_MFE_URL}/styles.css`} />
              <script src={`${COOKIEBAR_MFE_URL}/runtime.js`}></script>
              <script
                src={`${COOKIEBAR_MFE_URL}/main.js`}
                onLoad={`saveCookieBarStylesToStorage()`}
              ></script>
              {styles && <style id="banner-cookies-internal">{styles}</style>}
            </Helmet>

            <itau-cookie-consent-banner segment="varejo"></itau-cookie-consent-banner>
          </>
        }
      />
      <SEO meta={noIndexPage()} />
      <LayoutProvider>
        <OfferProvider>
          <LeadProvider>
            <RenegProvider>
              <DesenrolaProvider>
                <AuthOptinProvider>
                  <CustomizeSelectContractsProvider>
                    <main>{children}</main>
                  </CustomizeSelectContractsProvider>
                  <FeatureToggleWidget />
                </AuthOptinProvider>
              </DesenrolaProvider>
            </RenegProvider>
          </LeadProvider>
        </OfferProvider>
      </LayoutProvider>
    </>
  )
}

export default Layout
