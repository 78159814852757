import storage from "utils/storage"
import { generateAndGetCorrelationId } from "./correlationId"

export const generateSegmentPageViewPayload = (pageName) => {
  const segmentData = generateSegmentData()

  return {
    pageName,
    ...segmentData,
  }
}

export const generateSegmentData = () => {
  const correlationId = generateAndGetCorrelationId()
  const callback_url = storage.session.getItem("urlCallback")
  const lead_uuid = storage.session.getItem("lead_uuid")

  return {
    correlationId,
    source_url: window.location.href,
    callback_url,
    lead_uuid,
    isNew: true,
  }
}
