import { Modal } from "@mobi/ds"
import analytics from "@mobi/libraries/analytics"
import Text from "components/Text"
import { useEffect } from "react"
import handleInternalTracking from "utils/trackings/handleInternalTracking"
import Image from "components/Image"

const DesenrolaResimulateModal = ({
  isOpen,
  setIsOpen,
  handleResimulate,
  handleContinueHere,
}) => {
  useEffect(() => {
    if (isOpen) {
      analytics.track("gaVisualizedObject", "DesenrolaResimulateModal")
      analytics.track("pageView", {
        pageName: "DesenrolaBrasil/600+Ressimulacao",
      })
      analytics.track("segmentPageView", "DesenrolaBrasil600Ressimulacao")
    }
  }, [isOpen])

  return (
    <Modal
      type="center"
      show={isOpen}
      onCloseClick={() => setIsOpen(false)}
      primary={{
        action: async () => {
          analytics.track("gaButtonClick", {
            id: "dbae1499-5306-4e3c-b6d0-b1259e92b280",
            text: "Sim, ressimular contratos",
          })

          handleInternalTracking("desenrola_resimulate_modal:resimulated")
          analytics.track(
            "genericEvent",
            "desenrola_resimulate_modal:resimulated"
          )

          handleResimulate()
        },
        label: "Sim, ressimular contratos",
      }}
      secondary={{
        action: () => {
          analytics.track("gaButtonClick", {
            id: "3f27b535-5479-4d51-8ef8-8be977c60c9b",
            text: "Não quero oferta Desenrola",
          })

          handleInternalTracking("desenrola_resimulate_modal:continue_here")
          analytics.track(
            "genericEvent",
            "desenrola_resimulate_modal:continue_here"
          )

          handleContinueHere()
          setIsOpen(false)
        },
        label: "Não quero oferta Desenrola",
      }}
      className="desenrola-modal-container"
    >
      <div className="desenrola-modal">
        <Image
          src="/images/logo-desenrola.svg"
          alt="Logo Desenrola Brasil"
          className="desenrola-modal__logo"
          width="127"
          height="80"
        />
        <Text p size={4} className="desenrola-modal__text">
          Você selecionou contratos que podem ser liberados{" "}
          <strong>em breve</strong> para renegociação com condições especiais
          através do Desenrola Brasil.
        </Text>
        <Text p size={4} className="desenrola-modal__text">
          <strong>
            Gostaria de ressimular apenas os contratos que não serão
            contemplados no Desenrola Brasil?
          </strong>
        </Text>
      </div>
    </Modal>
  )
}

export default DesenrolaResimulateModal
