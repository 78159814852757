import { createContext, useContext, useState, useEffect } from "react"
import storage from "utils/storage"

const AuthOptinContext = createContext({})

export const useAuthOptin = () => {
  const context = useContext(AuthOptinContext)

  if (!context) {
    throw new Error(
      `useAuthOptin hook must be used within a 'AuthOptinProvider' component`
    )
  }

  return context
}

export const AuthOptinProvider = ({ children }) => {
  const [showingAuthOptIn, setShowingAuthOptIn] = useState(
    storage.session.getItem("showingAuthOptIn") || false
  )
  const [authOptInID, setAuthOptInID] = useState(
    storage.session.getItem("authOptInID") || null
  )
  const [showAuthOptInErrorAlert, setShowAuthOptInErrorAlert] = useState(
    storage.session.getItem("showAuthOptInErrorAlert") || false
  )

  const [optInSlugs, setOptInSlugs] = useState(
    storage.session.getItem("optInSlugs") || []
  )

  useEffect(() => {
    if (showingAuthOptIn) {
      storage.session.setItem("showingAuthOptIn", showingAuthOptIn)
    }
  }, [showingAuthOptIn])

  useEffect(() => {
    if (authOptInID) {
      storage.session.setItem("authOptInID", authOptInID)
    }
  }, [authOptInID])

  useEffect(() => {
    if (showAuthOptInErrorAlert) {
      storage.session.setItem(
        "showAuthOptInErrorAlert",
        showAuthOptInErrorAlert
      )
    }
  }, [showAuthOptInErrorAlert])

  useEffect(() => {
    if (optInSlugs) {
      storage.session.setItem("optInSlugs", optInSlugs)
    }
  }, [optInSlugs])

  return (
    <AuthOptinContext.Provider
      value={{
        showingAuthOptIn,
        authOptInID,
        showAuthOptInErrorAlert,
        optInSlugs,
        setShowingAuthOptIn,
        setAuthOptInID,
        setShowAuthOptInErrorAlert,
        setOptInSlugs,
      }}
    >
      {children}
    </AuthOptinContext.Provider>
  )
}
