import isClient from "@mobi/utils/browser/isClient"

const indexedList = ["/", "/registro/"]

const noIndexPage = () => {
  if (!isClient()) return []

  const { pathname } = window.location

  const isPageIndexed = indexedList.includes(pathname)
  if (isPageIndexed) return []

  return [
    {
      name: "robots",
      content: "noindex, nofollow, noimageindex, noarchive",
      "data-no-index": "true",
    },
  ]
}

export default noIndexPage
