import React, { createContext, useContext, useState } from "react"

const LeadContext = createContext({})

export const useLead = () => {
  const context = useContext(LeadContext)

  if (!context) {
    throw new Error(
      `useLead hook must be used within a 'LeadContext' component`
    )
  }

  return context
}

export const LeadProvider = ({ children }) => {
  const [debts, setDebts] = useState()
  return (
    <LeadContext.Provider value={{ setDebts, debts }}>
      {children}
    </LeadContext.Provider>
  )
}
