import { Modal } from "@mobi/ds"
import analytics from "@mobi/libraries/analytics"
import Text from "components/Text"
import { DESENROLA_URL } from "gatsby-env-variables"
import { useEffect } from "react"
import handleInternalTracking from "utils/trackings/handleInternalTracking"
import Image from "components/Image"

const DesenrolaOnly600Modal = ({ isOpen, setIsOpen, handleContinueHere }) => {
  useEffect(() => {
    if (isOpen) {
      analytics.track("gaVisualizedObject", "DesenrolaOnly600Modal")
      analytics.track("pageView", {
        pageName: "DesenrolaBrasil/600",
      })
      analytics.track("segmentPageView", "DesenrolaBrasil600")
    }
  }, [isOpen])

  return (
    <Modal
      type="center"
      show={isOpen}
      onCloseClick={() => setIsOpen(false)}
      primary={{
        action: async () => {
          analytics.track("gaButtonClick", {
            id: "2f8e47fe-a05c-404f-9499-63b42f7a94d4",
            text: "Sim, acessar Desenrola",
          })

          handleInternalTracking("desenrola_only_600_modal:desenrola")
          analytics.track("genericEvent", "desenrola_only_600_modal:desenrola")

          setIsOpen(false)
          window.open(DESENROLA_URL, "_self")
        },
        label: "Sim, acessar Desenrola",
      }}
      secondary={{
        action: () => {
          analytics.track("gaButtonClick", {
            id: "fa2fe0d3-1817-4123-b5ce-9fb25071e513",
            text: "Não quero oferta Desenrola",
          })

          handleInternalTracking("desenrola_only_600_modal:continue_here")
          analytics.track(
            "genericEvent",
            "desenrola_only_600_modal:continue_here"
          )

          handleContinueHere()
          setIsOpen(false)
        },
        label: "Não quero oferta Desenrola",
      }}
      className="desenrola-modal-container"
    >
      <div className="desenrola-modal">
        <Image
          src="/images/logo-desenrola.svg"
          alt="Logo Desenrola Brasil"
          className="desenrola-modal__logo"
          width="127"
          height="80"
        />
        <Text p size={4} className="desenrola-modal__text">
          Você selecionou contratos que podem ser liberados{" "}
          <strong>em breve</strong> para renegociação com condições especiais
          através do Desenrola Brasil.
        </Text>
        <Text p size={4} className="desenrola-modal__text">
          <strong>
            Gostaria de consultar mais informações sobre sua oferta especial
            Desenrola Brasil?
          </strong>
        </Text>
      </div>
    </Modal>
  )
}

export default DesenrolaOnly600Modal
