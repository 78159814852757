import { useEffect, useState } from "react"
import { changeFeatureToggleItem } from "utils/featureToggle"
import storage from "utils/storage"
import featureToggle, { featureToggleStatus } from "utils/featureToggle"
import "./FeatureToggleWidget.scss"

const { featureToggleWidgetActive } = featureToggle()

const FeatureToggleWidget = () => {
  const [items, setItems] = useState({})
  const [showWidget, setShowWidget] = useState(false)
  const [toggle, setToggle] = useState(false)

  const checkIfHasHash = () => {
    const hasFeatureToggleHash = window.location.hash === "#featureToggle"
    if (hasFeatureToggleHash) {
      storage.local.setItem("featureToggleActive", true)
      return
    }
    storage.local.setItem("featureToggleActive", false)
  }

  const onClickItem = (e) => {
    changeFeatureToggleItem(e.target.name, e.target.checked)
    window.location.reload()
  }

  const onClickToggle = () => {
    setToggle((state) => !state)
  }

  useEffect(() => {
    checkIfHasHash()
    const items = featureToggle()
    const itemsFormatted = Object.keys(items).map((key) => ({
      name: key,
      value: items[key],
      environment: featureToggleStatus[key],
    }))

    const activeOnStorage =
      storage.local.getItem("featureToggleActive") || false

    setItems(itemsFormatted)
    setShowWidget(activeOnStorage && featureToggleWidgetActive)
  }, [])

  if (!showWidget) {
    return null
  }

  return (
    <div className={`feature-toggle ${toggle ? "-active" : ""}`}>
      <h2 className="title">Feature Toggle</h2>
      <button className="toggle" onClick={onClickToggle}>
        {toggle ? "-" : "+"}
      </button>
      <ul className="items">
        {items.map(({ name, value, environment = [] }) => (
          <li className="item" key={name}>
            <p className="environment">
              active in: <span>{environment.join(" | ")}</span>
            </p>
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={value}
              onClick={onClickItem}
            />

            <label htmlFor={name}>{name}</label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FeatureToggleWidget
