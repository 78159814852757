export const handledErrors = [
  {
    error: "Not Found: Não foram encontrados contratos para este lead",
    redirect: "/semDivida",
  },
  {
    error: "invalid or expired jwt",
    redirect: "/registro",
  },
  {
    error: "fora do horário",
    redirect: "/foraHorario",
    checkIncludes: true,
  },
]

const verifyRedirectError = (error) => {
  const errorData = error.data
  const handledError = handledErrors.find((e) => {
    if (e.checkIncludes) {
      return errorData.message.includes(e.error)
    }

    return e.error === errorData.message
  })

  if (handledError) {
    return handledError.redirect
  }
}

export default verifyRedirectError
