import React, { createContext, useContext, useState } from "react"
import { useReneg } from "hooks/useReneg"
import DesenrolaOnly600Modal from "components/Desenrola/DesenrolaOnly600Modal"
import DesenrolaResimulateModal from "components/Desenrola/DesenrolaResimulateModal"
import storage, { handleStorageError } from "utils/storage"
import { useLead } from "contexts/Lead"

const DesenrolaContext = createContext({})

export const useDesenrola = () => {
  const context = useContext(DesenrolaContext)

  if (!context) {
    throw new Error(
      `useDesenrola hook must be used within a 'DesenrolaProvider' component`
    )
  }

  return context
}

export const DesenrolaProvider = ({ children }) => {
  const { handleResimulate } = useReneg()

  const { debts } = useLead()

  const [showDesenrolaResimulateModal, setShowDesenrolaResimulateModal] =
    useState(false)
  const [showDesenrolaOnly600Modal, setShowDesenrolaOnly600Modal] =
    useState(false)

  const [hasChooseContinueHere, setHasChooseContinueHere] = useState(() => {
    const storagedHasChooseContinueHere = handleStorageError(
      storage.session.getItem("hasChooseContinueHere"),
      false
    )
    return storagedHasChooseContinueHere || false
  })

  const checkDesenrola600Contracts = () => {
    if (hasChooseContinueHere) return

    const hasAtLeastOneDesenrola600Able = debts?.debtsGroups?.some(
      (debtGroup) => {
        return debtGroup?.contracts?.some(
          (contract) => contract.desenrola.status === "600"
        )
      }
    )
    const hasOnlyDesenrola600Able = debts?.debtsGroups?.every((debtGroup) => {
      return debtGroup?.contracts?.length
        ? debtGroup?.contracts?.every(
            (contract) => contract.desenrola.status === "600"
          )
        : false
    })

    if (hasAtLeastOneDesenrola600Able || hasOnlyDesenrola600Able) {
      return { hasAtLeastOneDesenrola600Able, hasOnlyDesenrola600Able }
    }
  }

  const handleDesenrola600Modal = ({
    hasAtLeastOneDesenrola600Able,
    hasOnlyDesenrola600Able,
  } = {}) => {
    if (hasOnlyDesenrola600Able) {
      return setShowDesenrolaOnly600Modal(true)
    }

    if (hasAtLeastOneDesenrola600Able) {
      return setShowDesenrolaResimulateModal(true)
    }
  }

  const handleResimulateWithout600 = async () => {
    const contractsWithoutDesenrola600 = debts?.debtsGroups?.reduce(
      (acc, debtGroup) => {
        const contracts = debtGroup?.contracts?.filter(
          (contract) => contract.desenrola.status !== "600"
        )

        if (contracts?.length) {
          acc.push(...contracts)
        }

        return acc
      },
      []
    )

    setShowDesenrolaResimulateModal(false)

    if (contractsWithoutDesenrola600.length) {
      return await handleResimulate(contractsWithoutDesenrola600)
    }
  }

  const handleContinueHere = () => {
    setHasChooseContinueHere(true)
    storage.session.setItem("hasChooseContinueHere", true)
  }

  return (
    <DesenrolaContext.Provider
      value={{
        handleDesenrola600Modal,
        checkDesenrola600Contracts,
      }}
    >
      {children}

      <DesenrolaResimulateModal
        isOpen={showDesenrolaResimulateModal}
        setIsOpen={setShowDesenrolaResimulateModal}
        handleResimulate={handleResimulateWithout600}
        handleContinueHere={handleContinueHere}
      />

      <DesenrolaOnly600Modal
        isOpen={showDesenrolaOnly600Modal}
        setIsOpen={setShowDesenrolaOnly600Modal}
        handleContinueHere={handleContinueHere}
      />
    </DesenrolaContext.Provider>
  )
}
