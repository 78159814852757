import router from "utils/router"
import storage from "./storage"
import {
  SYSTEM_TOKEN,
  SYSTEM_TOKEN_RECOVERY,
  SYSTEM_TOKEN_RECOVERY_BFF,
  SYSTEM_TOKEN_PIVO,
} from "gatsby-env-variables"

export const systemTokens = {
  reneg: SYSTEM_TOKEN,
  recovery: SYSTEM_TOKEN_RECOVERY,
  pivo: SYSTEM_TOKEN_PIVO,
  recovery_bff: SYSTEM_TOKEN_RECOVERY_BFF,
}

export const getSystemToken = (isBff) => {
  try {
    const source =
      router.getQueryString("utm_source") ||
      storage.session.getItem("utm_source")

    if (isBff && source === "recovery") {
      return systemTokens["recovery_bff"]
    }

    if (source && systemTokens[source]) {
      return systemTokens[source]
    }

    return systemTokens.reneg
  } catch (e) {
    return systemTokens.reneg
  }
}

export const setUtmSourceByQueryString = () => {
  const queryUtmSource = router.getQueryString("utm_source")

  if (queryUtmSource) {
    storage.session.setItem("utm_source", queryUtmSource)
  }
}

export const setCrossSellOrigin = () => {
  const queryUtmCampaign = router.getQueryString("utm_campaign")
  const queryUtmMedium = router.getQueryString("utm_medium")

  if (
    queryUtmCampaign === "cross-sell-reneg" ||
    queryUtmMedium === "cross-sell"
  ) {
    storage.session.setItem("is-cross-sell", true)
    return true
  }
}
