import isClient from "@mobi/utils/browser/isClient"
import { ENV } from "gatsby-env-variables"

function handleInternalTracking(event, props) {
  try {
    if (isClient() && event) {
      if (ENV !== "production") {
        console.log("internal tracking", event || "", props || "")
      }

      const tagsToSend = ["G-0S0SXEHB7N"]

      setTimeout(() => {
        tagsToSend.forEach((tag) => {
          window.gtag("event", event, { ...props, send_to: tag })
        })
      }, 500)

      return
    }

    throw new Error("window.gtag or event is not defined")
  } catch (err) {
    console.error("internal tracking", err)
  }
}

export default handleInternalTracking
