import SafeStorage from "@mobi/utils/browser/safeStorage"
import isClient from "@mobi/utils/browser/isClient"

/**
 * setInSafeStorage
 * @param {string} key The storage key
 * @param {any} value The value to be storaged
 * @param {{id:string}} options The id of the storage
 */
export const setInSafeStorage = async (key, value, { id } = {}) => {
  if (!key || !value) return
  if (!isClient()) return

  const safeStorage = await SafeStorage(id)

  const valueStringfy = JSON.stringify(value)
  await safeStorage?.set(key, valueStringfy)
}

/**
 * getFromSafeStorage
 * @param {string} key The storage key
 * @param {{id:string}} options The id of the storage
 */
export const getFromSafeStorage = async (key, { id } = {}) => {
  if (!key) return
  if (!isClient()) return

  const safeStorage = await SafeStorage(id)

  const value = await safeStorage?.get(key)

  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

/**
 * removeFromSafeStorage
 * @param {string} key The storage key
 * @param {{id:string}} options The id of the storage
 */
export const removeFromSafeStorage = async (key, { id } = {}) => {
  if (!key) return
  if (!isClient()) return

  const safeStorage = await SafeStorage(id)
  await safeStorage?.delete(key)
}
