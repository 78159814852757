import { navigate } from "gatsby"
import queryString from "query-string"
import isClient from "@mobi/utils/browser/isClient"

const redirectTo = (url) => (window.location.href = url)

const generateQueryString = (query) => {
  return Object.keys(query).reduce((acc, key) => {
    const value = query[key]
    if (value || value === 0) {
      return acc.concat(`${key}=${value}&`)
    }

    return acc
  }, "")
}

const push = ({ pathname = window.location.pathname, query = {} }) => {
  const queryStr = queryString.stringify(query)
  const newUrl = `${pathname}?${queryStr}`
  navigate(newUrl)
}

const getQueryString = (name) => {
  const queryParam = isClient() && window.location.search
  const params = new URLSearchParams(queryParam)
  return params.get(name)
}

const getQueryParams = (search) => {
  if (!search) return null

  const queries = search.replace("?", "").split("&")
  const objQueries = queries.reduce((obj, item) => {
    const [key, value] = item.split("=")
    return {
      ...obj,
      [key]: value,
    }
  }, {})

  return objQueries
}

const getPathName = () => {
  if (isClient()) {
    return window.location.pathname
  }

  return ""
}

export default {
  redirectTo,
  generateQueryString,
  getQueryString,
  getQueryParams,
  push,
  getPathName,
}
