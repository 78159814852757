import storage from "./storage"

export const HOMES_LIST = ["/", "/cartoes/"]

/**
 * @returns {string} the pathname of the home accessed by the user is returned. e.g: "/cartoes/"
 */

const handleBackToHome = () => {
  const startSourceURL = storage.session.getItem("startSourceURL")
  if (!startSourceURL) return "/"

  const startURL = new URL(startSourceURL)

  const origin = HOMES_LIST.includes(startURL.pathname)
    ? startURL.pathname
    : "/"

  return origin
}

export default handleBackToHome
