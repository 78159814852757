import { GIT_VERSION } from "gatsby-env-variables"
import isClient from "@mobi/utils/browser/isClient"

import "styles/index.scss"

if (isClient()) {
  window.__VERSION__ = GIT_VERSION
}

function wrapElement({ element }) {
  return <>{element}</>
}

export default wrapElement
