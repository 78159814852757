export const focusInElement = (selector, options = {}) => {
  const timeout = options.timeout || 100
  const preventScroll = options.preventScroll || false

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const el = document.querySelector(selector)
        if (el) {
          el.focus({ preventScroll })
          return resolve(el)
        }
      } catch (error) {
        return reject(error)
      }
    }, timeout)
  })
}

export const scrollToElement = (selector, options = {}) => {
  const behavior = options.behavior || "smooth"

  document.querySelector(selector).scrollIntoView({
    behavior,
  })
}

export default {
  focusInElement,
  scrollToElement,
}
