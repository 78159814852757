import { createElement } from "react"
import "./Text.scss"
const TAGS_AVAILABLE = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "p",
  "span",
  "strong",
  "b",
  "label",
]
const getTagName = (props) => {
  const propKeys = Object.keys(props)
  const tag = propKeys.find((key) => TAGS_AVAILABLE.includes(key))
  if (!tag) {
    return "div"
  }
  return tag
}
const omitTagProps = (props) => {
  const newProps = {}
  for (const key in props) {
    const isTag = TAGS_AVAILABLE.includes(key)
    if (!isTag) {
      newProps[key] = props[key]
    }
  }
  return newProps
}
const defaultAttrs = {
  "data-contrast": "text",
}
const Text = ({ children, size, ...props }) => {
  const tagName = getTagName(props)
  const isText = tagName === "p" || tagName === "label" || tagName === "span"
  const typeClass = isText ? `text` : "text-heading"
  const sizeClass = size ? `rvc-${typeClass}-size-${size}` : ""
  const { className = "", ...componentProps } = omitTagProps(props)
  const wrapperClasses = `${className} rvc-${typeClass}-${tagName} ${sizeClass}`
  const newElementProps = {
    ...defaultAttrs,
    ...componentProps,
    className: wrapperClasses,
  }
  return createElement(tagName, newElementProps, children)
}
export default Text
