import storage, { handleStorageError } from "utils/storage"
import router from "utils/router"
import { setCrossSellOrigin } from "utils/crossSell"
import ObjectHelper from "utils/objects"

const pageLoadSnippets = {
  ["DataNascimento"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["SemDividasEncontradas"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["SemDividasRenegociaveis"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["ForaDoHorario"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["ErroGenerico"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["Oferta"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["ContratosRenegociar"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["Personalizacao"]: ({ idproposta }) => {
    return {
      custom: {
        idproposta,
      },
    }
  },
  ["Resumo"]: ({ prazofinanciamento, idproposta, vba, volsim, parc }) => {
    return {
      visitor: {
        prazofinanciamento,
      },
      custom: {
        idproposta,
        vba,
        volsim,
        parc,
      },
    }
  },
  ["Conclusao"]: ({
    transactionID,
    idproposta,
    vba,
    volsim,
    parc,
    prazofinanciamento,
  }) => {
    return {
      product: {
        confirmacao: {
          transactionID,
        },
      },
      custom: {
        idproposta,
        vba,
        volsim,
        parc,
      },
      visitor: {
        prazofinanciamento,
      },
    }
  },
  ["DetalhesAcordo"]: ({
    transactionID,
    idproposta,
    vba,
    volsim,
    parc,
    whatevervariable,
    prazofinanciamento,
  }) => {
    return {
      product: {
        confirmacao: {
          transactionID,
        },
      },
      custom: {
        idproposta,
        vba,
        volsim,
        parc,
        whatevervariable,
      },
      visitor: {
        prazofinanciamento,
      },
    }
  },
}

export const createPageLoad = (pageName, dynamicData) => {
  const name = `IT:NL:NCC:Renegocie:${pageName}`
  const snippet = pageLoadSnippets[pageName]
  const payloadFromSnippet = snippet ? snippet(dynamicData) : {}

  const utmSourceStorage = storage.session.getItem("utm_source")
  const utmSource = utmSourceStorage || router.getQueryString("utm_source")

  const isCrossSellStorage = storage.session.getItem("is-cross-sell")
  const isCrossSellQuery = setCrossSellOrigin()
  const isCrossSell = isCrossSellStorage || isCrossSellQuery

  const clientOrigin = isCrossSell ? utmSource : "reneg"

  const defaultPayload = {
    site: {
      negocio: "PF",
      tipoDeCanal: "Web",
    },
    page: {
      nome: name,
    },
    visitor: {
      origemCliente: clientOrigin,
    },
    rule: "pageLoad",
  }

  const payload = ObjectHelper.mergeDeepObject(
    payloadFromSnippet,
    defaultPayload
  )
  return handleGAPayload(payload)
}

export const handleGAPayload = (payload) => {
  const anonymousId = window?.analytics?.user
    ? window.analytics?.user()?.anonymousId()
    : ""

  const leadUUID = handleStorageError(storage.session.getItem("lead_uuid"), "")

  const startSourceUrl =
    (typeof window !== "undefined" &&
      storage.session.getItem("startSourceURL")) ||
    window.location.href

  const { events = {} } = payload
  const payloadWithLeadId = {
    ...payload,
    custom: {
      ...payload.custom,
      ...events.custom,
      anonymousId,
      startSourceUrl,
      lead_id: leadUUID,
    },
  }

  return payloadWithLeadId
}
