import dateFormat from "@mobi/utils/formatters/date"
import format from "@mobi/utils/formatters/number"
import { removeFromSafeStorage, setInSafeStorage } from "utils/safeStorage"

export const DealResumeDetail = (data) => {
  const dealResume = data?.summaries

  const handleContracts = (contracts) => {
    if (!contracts) {
      return []
    }

    return contracts.map((contract) => {
      return {
        ...contract,
        new_limit: contract.new_limit
          ? format({
              value: Number(contract.new_limit),
              style: "currency",
            }).substring(3)
          : null,
        previous_limit: contract.previous_limit
          ? format({
              value: Number(contract.previous_limit),
              style: "currency",
            }).substring(3)
          : null,
      }
    })
  }

  const handleIOF = (iof, iofDescription) => {
    if (!iof) return null

    if (iof === "*") return iofDescription

    const iofValue = Number(iof) || 0
    return `${iofValue.toString()}%`
  }

  const groupDebtBankAccounts = dealResume?.filter(
    (item) => item?.installment_debt_accounts
  )[0]?.installment_debt_accounts

  if (groupDebtBankAccounts) {
    setInSafeStorage("groupDebtBankAccounts", groupDebtBankAccounts)
  } else {
    removeFromSafeStorage("groupDebtBankAccounts")
  }

  return {
    optins: data?.optins || [],
    summaries: dealResume.map((deal) => {
      const feesNumber = deal.interest_rate_monthly
        ? Number(deal.interest_rate_monthly)
        : null
      const feesYearlyNumber = deal.interest_rate_yearly
        ? Number(deal.interest_rate_yearly)
        : null

      const cetNumber = deal.effective_cost ? Number(deal.effective_cost) : null
      const cetYearlyNumber = deal.effective_cost_yearly
        ? Number(deal.effective_cost_yearly)
        : null

      return {
        title: deal.group_name,
        date:
          dateFormat(deal.due_date) ||
          dateFormat(deal.first_installment_due_date),
        generalConditionId: deal.general_condition_id,
        installmentNumber: deal.installment_number,
        installmentValue: format({
          value: deal.installment_value ? deal.installment_value : null,
          style: "currency",
        }).substring(3),
        fees: feesNumber ? feesNumber?.toFixed(2) : null,
        fees_yearly: feesYearlyNumber ? feesYearlyNumber?.toFixed(2) : null,
        cet: cetNumber ? cetYearlyNumber?.toFixed(2) : null,
        cet_yearly: cetYearlyNumber ? cetYearlyNumber?.toFixed(2) : null,
        iof: handleIOF(deal.iof_value, deal.iof_description),
        otherInstallmentDates: deal.other_installments_due_day,
        originalValue: format({
          value: deal.original_value,
          style: "currency",
        }).substring(3),
        totalBalance: format({
          value: deal.total_balance,
          style: "currency",
        }).substring(3),
        summaryId: deal.summary_id,
        totalValue: format({
          value: deal.total_value,
          style: "currency",
        }).substring(3),
        contracts: handleContracts(deal.contract_details),
        firstInstallmentPaymentMethod:
          deal.first_installment_payment_method || "boleto",
        otherInstallmentPaymentMethod:
          deal.other_installment_payment_method || "boleto",
        savedValue: format({
          value: deal.saved_value,
          style: "currency",
        }).substring(3),
        openFinance: deal.open_finance,
        interestFree: deal.is_interest_free,
        bankAccounts: deal?.installment_debt_accounts,
        simulationId: deal.simulation_id,
        paymentMethodId: deal.payment_method_id,
        isPaymentMethodChangeable: deal.is_payment_method_changeable,
      }
    }),
  }
}
