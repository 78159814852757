import { navigate } from "gatsby"
import services from "services"
import backToHome from "utils/backToHome"
import featureToggle from "utils/featureToggle"
import storage from "utils/storage"

const { isMyAgreementsActive } = featureToggle()

const handleSumNonRenegotiableContracts = (reneg, key) =>
  reneg?.reduce((total, obj) => {
    const nonRenegotiableContracts = obj[key].non_renegotiable_contracts

    if (!!nonRenegotiableContracts) {
      return obj[key].non_renegotiable_contracts + total
    }

    return []
  }, 0)

const handleSumSummaryValues = (reneg, key) => {
  let simulation_initial_value = 0
  let simulation_final_value = 0
  let simulation_discount = 0

  reneg.forEach((group) => {
    const summary = group[key].simulation
      ? group[key].simulation.summary
      : group[key].summary

    simulation_initial_value += summary.simulation_initial_value
    simulation_final_value += summary.simulation_final_value
    simulation_discount += summary.simulation_discount
  })

  const result = {
    simulation_initial_value,
    simulation_final_value,
    simulation_discount,
  }

  return result || {}
}

const handleSimulateRequest = async ({ payload, sessionAccessToken }) => {
  const accessToken =
    sessionAccessToken || storage.local.getItem("sessionAccessToken")

  try {
    const {
      data: { reneg },
    } = await services.bff.post("/simulation", payload, {
      headers: {
        Token: `Bearer ${accessToken}`,
      },
      throwError: true,
    })

    if (!reneg || reneg.every((group) => !group)) {
      throw new Error(
        "handleSimulateRequest | Try to simulate but reneg return is null"
      )
    }

    if (!reneg.length) {
      throw new Error(
        "handleSimulateRequest | Try to simulate but reneg return is empty []"
      )
    }

    const groupBySimulation = reneg.reduce((acc, values) => {
      const groupsList = values ? Object.entries(values) : []

      if (!groupsList.length) return acc

      groupsList.forEach((group) => {
        const key = group[0]
        const simulationOption = values[key]

        const simulationObject =
          simulationOption?.simulation || simulationOption
        const debtGroups = simulationObject?.debts_group

        if (!debtGroups || !debtGroups.length) return acc

        const summary = simulationOption.simulation
          ? simulationOption.simulation.summary
          : simulationOption.summary

        if (!summary || !Object.keys(summary).length) {
          console.error(
            "handleSimulateRequest | the summary of simulation option is empty"
          )
          return {}
        }

        const sumSummaries = handleSumSummaryValues(reneg, key)

        const newSummary = { ...summary, ...sumSummaries }

        const newNonRenegotiableContracts = handleSumNonRenegotiableContracts(
          reneg,
          key
        )

        if (!acc[key]) {
          if (!!simulationOption.simulation) {
            acc[key] = {
              ...simulationOption,
              debts_group: [...debtGroups],
              simulation: {
                ...simulationOption.simulation,
                summary: newSummary,
              },
              non_renegotiable_contracts: newNonRenegotiableContracts,
            }
          } else {
            acc[key] = {
              ...simulationOption,
              debts_group: [...debtGroups],
              summary: newSummary,
              non_renegotiable_contracts: newNonRenegotiableContracts,
            }
          }
        }

        debtGroups.map((group) => {
          if (!acc[key].debts_group.includes(group)) {
            return acc[key].debts_group.push(group)
          }
        })
      })

      return acc
    }, {})

    if (!Object.keys(groupBySimulation).length) {
      navigate("/error")
      console.error(
        "handleSimulateRequest | Try to group by simulation but the final return is empty"
      )

      return
    }

    const {
      cash_simulation_response,
      delayed_simulation_response,
      installment_simulation_response,
      hot_deal_simulation_response,
    } = groupBySimulation

    return {
      cash_simulation_response: Object.keys(cash_simulation_response || {})
        .length
        ? cash_simulation_response
        : {},
      delayed_simulation_response: Object.keys(
        delayed_simulation_response || {}
      ).length
        ? delayed_simulation_response
        : {},
      installment_simulation_response: Object.keys(
        installment_simulation_response || {}
      ).length
        ? installment_simulation_response
        : {},
      hot_deal_response: Object.keys(hot_deal_simulation_response || {}).length
        ? hot_deal_simulation_response
        : {},
    }
  } catch (error) {
    console.error("error to simulate --", error)

    const agreementsData = await storage.session.getItem("agreementsData")
    const hasAgreementsData = await agreementsData?.agreements?.length

    if (isMyAgreementsActive && hasAgreementsData) {
      navigate("/meusacordos", {
        state: {
          navigatedByUser: true,
          backTo: backToHome(),
        },
      })
      return
    } else {
      navigate("/error")
      console.error("handleSimulateRequest | error to simulate")
      return
    }
  }
}

export default handleSimulateRequest
