import isClient from "@mobi/utils/browser/isClient"
import { segment, ga } from "@mobi/libraries/analytics"
import { createPageLoad, handleGAPayload } from "./gaHelpers"
import {
  generateSegmentData,
  generateSegmentPageViewPayload,
} from "./segmentHelpers"

const trackings = {
  pageView: ({ pageName, pageData }) => {
    const payload = createPageLoad(pageName, pageData)
    ga.page(payload)
  },

  LPPageView: () => {
    const pageData = generateSegmentPageViewPayload("LP")
    segment.track("lp_page_view", pageData)
  },
  LPPageViewCartoes: () => {
    const pageData = generateSegmentPageViewPayload("LPCartoes")
    segment.track("lp_page_view_cartoes", pageData)
  },
  segmentPageView: (pageName) => {
    const pageData = generateSegmentPageViewPayload(pageName)
    segment.page("page_view", pageData)
  },
  segmentButtonClick: ({ name, id }) => {
    const segmentData = generateSegmentData()
    const payload = {
      ...segmentData,
      name,
      id,
    }

    segment.track("button_click", payload)
  },
  segmentElementViewed: (elementName) => {
    const segmentData = generateSegmentData()
    const payload = {
      ...segmentData,
      elementName,
    }

    segment.track("element_viewed", payload)
  },
  segmentVWOTest: ({ experimentId, variationName, pageName }) => {
    const defaultPayload = generateSegmentPageViewPayload(pageName)

    const payload = {
      ...defaultPayload,
      experimentId,
      variationName,
      vwoUserId: isClient() && window?._vwo_uuid,
    }

    segment.track("VWO Test", payload)
  },
  segmentLikertFeedback: (data) => {
    const payload = {
      ...generateSegmentData(),
      ...data,
    }

    segment.track("likert_feedback", payload)
  },
  segmentGenericEvent: (data) => {
    const { name, eventParams } = data

    const segmentData = generateSegmentData()
    const payload = {
      ...segmentData,
      ...eventParams,
    }

    segment.track(name, payload)
  },

  gaButtonClick: ({
    id,
    text,
    transactionName,
    transactionDetails,
    prazofinanciamento,
    idproposta,
    vba,
    volsim,
    whatevervariable,
    parc,
  }) => {
    const payload = handleGAPayload({
      events: {
        id: id,
        category: "Clique",
        action: "Inicio",
        label: `BTN:Renegocie:${text}`,
        noInteraction: "false",
      },
      transaction: {
        name: transactionName,
        details: transactionDetails,
      },
      visitor: {
        prazofinanciamento,
      },
      custom: {
        idproposta,
        vba,
        volsim,
        whatevervariable,
        parc,
      },
      rule: "customLink",
    })

    ga.track(payload)
  },
  gaVisualizedObject: (eventName) => {
    const payload = handleGAPayload({
      events: {
        category: "Objeto Visto",
        action: "Inicio",
        label: `CRD:Renegocie:${eventName}`,
        noInteraction: "true",
      },
      rule: "customLink",
    })

    ga.track(payload)
  },
  gaGenericEvent: (eventParams) => {
    const payload = handleGAPayload({
      events: {
        noInteraction: "true",
        ...eventParams,
      },
      rule: "customLink",
    })

    ga.track(payload)
  },

  //TODO: remove this method when Desenrola is removed
  genericEvent: (eventName) => {
    const payload = handleGAPayload({
      events: {
        noInteraction: "true",
        label: eventName,
      },
      rule: "customLink",
    })

    ga.track(payload)
    segment.track(eventName)
  },
}

export default trackings
