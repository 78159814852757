const isObject = (item) => {
  return item && typeof item === "object" && !Array.isArray(item)
}

const mergeDeepObject = (target, source) => {
  let output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeepObject(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

export default {
  isObject,
  mergeDeepObject,
}
