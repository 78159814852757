import "./Loader.scss"
import { useEffect } from "react"

let timeoutIdExhibition = null
let timeoutIdDelay = null

const Loader = ({ introText, mainText, show }) => {
  const handleTextAnimation = () => {
    const animationContent = document.querySelectorAll(
      ".animation-container__content"
    )

    const exhibition_duration = 4000
    const delay_between_phrases = 600

    const removeClassIfExists = () => {
      animationContent.forEach((element) => {
        if (
          element.classList.contains("animation-container__content--animate-in")
        ) {
          element.classList.remove("animation-container__content--animate-in")
        }
        if (
          element.classList.contains(
            "animation-container__content--animate-out"
          )
        ) {
          element.classList.remove("animation-container__content--animate-out")
        }
      })
    }

    const animateContent = (element) => {
      removeClassIfExists()

      element.classList.add("animation-container__content--animate-in")

      timeoutIdExhibition = setTimeout(() => {
        element.classList.remove("animation-container__content--animate-in")
        element.classList.add("animation-container__content--animate-out")

        timeoutIdDelay = setTimeout(() => {
          const nextElement = element.nextElementSibling || animationContent[0]
          animateContent(nextElement)
        }, delay_between_phrases)
      }, exhibition_duration)
    }

    animateContent(animationContent[0])
  }

  useEffect(() => {
    if (show) {
      handleTextAnimation()
    }
    return () => {
      clearTimeout(timeoutIdExhibition)
      clearTimeout(timeoutIdDelay)
    }
  }, [show])

  const handleAriaHidden = (text) => {
    const textSplit = text.split("|")

    const textP1 = textSplit[0]
    const hiddenText = textSplit[1]

    return (
      <>
        {textP1} <span aria-hidden={true}>{hiddenText}</span>
      </>
    )
  }

  return (
    <section
      aria-hidden={!show}
      className={`section-loader ${show ? "show" : "hide"}`}
    >
      <div className="animation-container">
        <div className="animation-container__content">
          {introText && (
            <span className="animation-container__text">{introText}</span>
          )}
          {mainText && (
            <span className="animation-container__text animation-container__text--main">
              {handleAriaHidden(mainText)}
            </span>
          )}
        </div>
        <div className="animation-container__content">
          <span className="animation-container__text">Negociação com</span>
          <span className="animation-container__text animation-container__text--main">
            parcelas que cabem no seu bolso, é no Itaú
          </span>
        </div>
        <div className="animation-container__content">
          <span className="animation-container__text">Aproveite as</span>
          <span className="animation-container__text animation-container__text--main">
            melhores condições. <br />É fácil, rápido e seguro
          </span>
        </div>
        <div className="animation-container__content">
          <span className="animation-container__text">
            Uma nova oportunidade
          </span>
          <span className="animation-container__text animation-container__text--main">
            de manter as contas em dia, é com o Itaú.
          </span>
        </div>
      </div>

      <div className="animated-circle"></div>
    </section>
  )
}

export default Loader
