import isClient from "@mobi/utils/browser/isClient"
import storage from "utils/storage"

let hasInitialized = false

const handleError = (message) =>
  console.log(`\x1b[31m GA4 - ${message} \x1b[0m`)

const setup = (isInitialization = false) => {
  return new Promise((resolve, reject) => {
    if (isClient()) {
      let attempts = 0
      const intervalId = setInterval(() => {
        const hasSetted = Boolean(window?.ItauDigitalAnalytics?.track)
        const canResolve = hasSetted && (isInitialization || hasInitialized)
        if (canResolve) {
          console.log(`\x1b[32m GA4 - Script loaded \x1b[0m`)
          clearInterval(intervalId)
          hasInitialized = true
          resolve()
        }

        attempts++
        if (attempts === 100) {
          clearInterval(intervalId)
          reject(new Error("GA4 - Script load attempts exceeded"))
        }
      }, 100)
    } else
      reject(
        new Error(
          "GA4 - Script loading is not supported in a non-client environment"
        )
      )
  })
}

/**
 * GA4 Pattern
 * - lower case string
 * - no accents
 * - no special characters
 * - white spaces replaced with a "-"
 */
export const normalizeParameters = (parameters) => {
  const normalizeValue = (value) =>
    String(value)
      .toLowerCase()
      .normalize("NFKD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9-/:]/g, "")

  const normalizedParameters = {}
  Object.entries(parameters).forEach(([key, value]) => {
    const hasData = value !== undefined && value !== null
    if (hasData) normalizedParameters[key] = normalizeValue(value)
  })
  return {
    ...normalizedParameters,
    implementation_team: "reneg:v1",
  }
}

const track = async (payload, options) => {
  try {
    await setup()

    if (options?.setUserProperties) {
      window?.ItauDigitalAnalytics?.setUserProperties({
        analytics: {
          user: {
            pf_id: storage.session.getItem("lead_uuid"),
          },
        },
      })
    }

    const parameters = payload.analytics.parameters
    payload.analytics.parameters = normalizeParameters(parameters)
    return window?.ItauDigitalAnalytics?.track(payload)
  } catch (error) {
    handleError(error.message)
  }
}

const setDefaultParameters = async () => {
  try {
    await setup(true)
    return window?.ItauDigitalAnalytics?.setDefaultParameters({
      analytics: {
        parameters: {
          business: "ncc",
          environment: "nao-logado",
          business_channel: "renegociacao",
        },
      },
    })
  } catch (error) {
    handleError(error.message)
  }
}

export default { track, setDefaultParameters }
