import axios from "axios"
import https from "https"
import { navigate } from "gatsby-link"

import { getSystemToken } from "utils/crossSell"
import storage from "utils/storage"
import verifyRedirectError from "../utils/redirectByError"

import {
  API_INTEGRADORA_ROOT,
  INTEGRADORA_APP_KEY,
  SYSTEM_TOKEN,
} from "gatsby-env-variables"

const handleApiBusiness = (config) => {
  const ignoredUrls = ["/authorization", "/lead", "/tokens", "/otp/send"]
  const isIgnoredUrl = ignoredUrls.some((url) => config.url.includes(url))

  if (isIgnoredUrl) return config

  config.params = {
    ...config.params,
    business: "reneg",
  }

  return config
}

const handleIntegradoraSystemToken = async (config) => {
  config.headers["SystemToken"] = getSystemToken(true)
  return config
}

const handleResetDebtsGroups = (debtsGroups) => {
  const isHotDealSession = storage.session.getItem("isHotDealSession")

  return debtsGroups?.map((debt) => {
    if (!debt.paymentPlan?.length) return debt

    const hotDealPaymentPlan = debt.paymentPlan.filter(
      (payment) => payment.installmentNumber === 6
    )?.[0]
    const firstPaymentPlan = [...debt.paymentPlan][0]

    const lastPaymentPlan = [...debt.paymentPlan].pop()

    const paymentPlanSelected = isHotDealSession
      ? hotDealPaymentPlan || firstPaymentPlan
      : lastPaymentPlan

    return {
      ...debt,
      selectedPaymentPlan: paymentPlanSelected,
    }
  })
}

const handleError = async (error) => {
  const originalRequest = error.config
  const { throwError } = originalRequest

  const responseError = error?.response?.data ? error?.response?.data : error

  if (throwError) {
    return Promise.reject(responseError)
  }

  const { status } = error.response || {}
  const { responseURL } = error.request
  const redirectTo = verifyRedirectError(error.response)

  const defaultRedirect = () => {
    navigate("/error", {
      state: {
        origin: responseURL.substr(responseURL.lastIndexOf("/") + 1),
      },
    })
    return Promise.reject({
      redirected: true,
      responseError: error?.response?.data,
    })
  }

  if (!error.response.status) {
    return defaultRedirect()
  }

  if (redirectTo) {
    if (redirectTo === "/registro") {
      navigate("/registro", {
        state: {
          source_url: responseURL.substr(responseURL.lastIndexOf("/") + 1),
        },
      })

      return
    }

    if (redirectTo === "/foraHorario") {
      const recoveryData = storage.session.getItem("recoveryHasDebts")

      if (recoveryData) {
        storage.session.setItem("fromRegister", true)

        navigate("/recovery")
      } else {
        navigate(redirectTo)
        return Promise.reject({
          redirected: true,
          responseError: error?.response?.data,
        })
      }
    }

    navigate(redirectTo)
    return Promise.reject({
      redirected: true,
      responseError: error?.response?.data,
    })
  }

  if ([503, 400, 429].includes(status)) {
    if (responseURL.includes("resume")) {
      const paymentOption = storage.session.getItem("paymentOption")

      if (["installmentSimulation", "cashSimulation"].includes(paymentOption)) {
        const storageDebts = storage.session.getItem(paymentOption)
        const newDebts = {
          ...storageDebts,
          debtsGroups: handleResetDebtsGroups(storageDebts.debtsGroups),
          originalValue:
            storageDebts.originalTotalValue || storageDebts.originalValue,
          currentValue:
            storageDebts.currentTotalValue || storageDebts.currentValue,
        }

        storage.session.setItem(paymentOption, newDebts)
      }

      if (status === 503) {
        navigate("/error", {
          state: {
            origin: "resume",
            isShowContact: false,
          },
        })
      }
    }

    return defaultRedirect()
  }

  return Promise.reject(error)
}

const api = axios.create({
  baseURL: API_INTEGRADORA_ROOT,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
    minVersion: "TLSv1",
  }),
  headers: {
    App: INTEGRADORA_APP_KEY,
    SystemToken: SYSTEM_TOKEN,
  },
})

api.interceptors.request.use(handleApiBusiness, handleError)
api.interceptors.request.use(handleIntegradoraSystemToken, handleError)
api.interceptors.response.use(undefined, handleError)

export default api
