import { createContext, useContext, useState, useEffect } from "react"
import storage from "utils/storage"

const CustomizeSelectContractsContext = createContext({})

export const useCustomizeSelectContractsContext = () => {
  const context = useContext(CustomizeSelectContractsContext)

  if (!context) {
    throw new Error(
      `useCustomizeSelectContracts hook must be used within a 'CustomizeSelectContractsProvider' component`
    )
  }

  return context
}

export const CustomizeSelectContractsProvider = ({ children }) => {
  const [selectedContractsList, setSelectedContractsList] = useState(
    storage.session.getItem("customizeSelectedContracts") || []
  )

  useEffect(() => {
    if (selectedContractsList) {
      storage.session.setItem(
        "customizeSelectedContracts",
        selectedContractsList
      )
    }
  }, [selectedContractsList])

  return (
    <CustomizeSelectContractsContext.Provider
      value={{
        selectedContractsList,
        setSelectedContractsList,
      }}
    >
      {children}
    </CustomizeSelectContractsContext.Provider>
  )
}
